import * as React from "react"
import HeardTopWrapper from "../components/header-wrapper"
import Layout from "../components/layout/index"
import PageContainer from "../components/page-container"
import Pricing from "../components/pricing/version2"
import { Helmet } from 'react-helmet';
import { graphql } from "gatsby"
const PricingBox = (props) => {
  const {products} = props.data
  return <>
    <HeardTopWrapper title="Our Price Plans"/>
    <Layout>
      <Helmet>
          <title>My Kitchen Inspector｜Pricing</title>
      </Helmet>
      <PageContainer>
        <Pricing data={products} />
      </PageContainer>
    </Layout>
  </>
}
  


export default PricingBox

export const  query = graphql`
 query ($language: String!) {

  locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

  products: allWpSimpleProduct {
      edges {
       node {
         id
         # type
         name
         expiresIn
         price
         link
         slug
         image {
           id
           altText
           sourceUrl
         }
       attributes {
       nodes{ 
         name
         options
         }
       }
       }
     }
    }
}
`